import React, { useRef, useEffect, useState } from "react";
import "./Background.css";

const Background = () => {
  const canvasRef = useRef(null);
  const ctx = useRef(null);
  const starsRef = useRef([]);

  const [stars, setStars] = useState([]);

  const starNum = 200;

  useEffect(() => {
    const init = () => {
      const newStars = [];
      for (let i = 0; i < starNum; i++) {
        newStars.push({
          x: Math.random() * ctx.current.canvas.width,
          y: Math.random() * ctx.current.canvas.height,
          radius: Math.random() * 2 + 0.8,
          vx: (Math.random() - 0.5) / 2,
          vy: (Math.random() - 0.5) / 2,
          alpha: Math.random() * 0.5 + 0.5,
        });
      }
      setStars(newStars);
      starsRef.current = newStars;
    };

    const animate = () => {
      requestAnimationFrame(animate);

      ctx.current.fillStyle = "black";
      ctx.current.fillRect(
        0,
        0,
        ctx.current.canvas.width,
        ctx.current.canvas.height
      );

      for (let i = 0; i < starsRef.current.length; i++) {
        const star = starsRef.current[i];

        ctx.current.beginPath();
        ctx.current.arc(star.x, star.y, star.radius, 0, Math.PI * 2);

        ctx.current.globalAlpha = star.alpha;
        ctx.current.fillStyle = "#855555";
        ctx.current.fill();

        star.x += star.vx;
        star.y += star.vy;

        if (star.x < -star.radius) {
          star.x = ctx.current.canvas.width + star.radius;
        }
        if (star.y < -star.radius) {
          star.y = ctx.current.canvas.height + star.radius;
        }
        if (star.x > ctx.current.canvas.width + star.radius) {
          star.x = -star.radius;
        }
        if (star.y > ctx.current.canvas.height + star.radius) {
          star.y = -star.radius;
        }
      }
    };

    ctx.current = canvasRef.current.getContext("2d");
    canvasRef.current.width = window.innerWidth;
    canvasRef.current.height = window.innerHeight;

    init();
    animate();

    return () => {
      starsRef.current = [];
    };
  }, []);

  return <canvas ref={canvasRef} />;
};

export default Background;
