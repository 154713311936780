import React, { useState } from "react";
import "./ReviewItem.css";
import RadarGraph from "./RadarGraph";

const ReviewItem = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const ratingStyle = {
    clipPath: `inset(0 calc(${12 - review.score.samlet} * 100% / 12) 0 0)`,
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const iconClassName = `material-icons expand-icon ${
    isExpanded ? "" : "flip"
  }`;

  return (
    <div className="review-item" onClick={handleToggleExpand}>
      <div className="folded">
        <h3 className="review-title">{review.sort}</h3>
        <div className="rating-wrapper">
          <div className="rating" style={ratingStyle}>
            <span className="score">{review.score.samlet}</span>
          </div>
        </div>
        <i className={iconClassName}> expand_less</i>
      </div>
      {isExpanded && (
        <div className="unfolded">
          <div className="description">
            {review.beskrivelse}
            <div className="date">{review.dato.split("T")[0]}</div>
          </div>
          <div className="graph">
            <RadarGraph review={review}></RadarGraph>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewItem;
