import React from "react";
import "./HeaderTitle.css";

const HeaderTitle = ({ title }) => {
  return (
    <div className="header-title-wrapper">
      <div className="top">{title}</div>
      <div className="bottom" aria-hidden="true">
        {title}
      </div>
    </div>
  );
};

export default HeaderTitle;
