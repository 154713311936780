import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Background from "../Background";
import InfoBox from "../InfoBox";
import ReviewItem from "./ReviewItem";
import "./Aebler.css";

const Aebler = () => {
  const [reviews, setReviews] = useState([]);
  const [sortedReviews, setSortedReviews] = useState([]);
  const [sortBy, setSortBy] = useState("samlet");
  const [sortOrder, setSortOrder] = useState(true);

  useEffect(() => {
    // Fetch reviews from the API
    fetch("https://api.ulriksen.vip/reviews/aebler")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }
        return response.json();
      })
      .then((data) => {
        // Set the reviews without sorting first
        setReviews(data);

        // Sort the reviews by "samlet" descending initially
        const sortedReviews = data.slice().sort((a, b) => {
          const scoreA = parseFloat(a.score.samlet);
          const scoreB = parseFloat(b.score.samlet);
          return scoreB - scoreA;
        });
        setSortedReviews(sortedReviews);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
        console.log("Error status:", error.status);
        console.log("Error message:", error.message);
      });
  }, []);

  const sortReviews = (sortBy, sortOrder) => {
    const sortedReviews = [...reviews].sort((a, b) => {
      if (sortBy === "dato") {
        const dateA = new Date(a[sortBy]).getTime();
        const dateB = new Date(b[sortBy]).getTime();

        let comparison = 0;
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }

        return sortOrder ? -comparison : comparison;
      } else {
        const valueA = parseFloat(getNestedFieldValue(a, `score.${sortBy}`));
        const valueB = parseFloat(getNestedFieldValue(b, `score.${sortBy}`));

        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }

        return sortOrder ? -comparison : comparison;
      }
    });

    setSortedReviews(sortedReviews);
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  // Helper function to get nested field value from an object
  const getNestedFieldValue = (object, key) => {
    const keys = key.split(".");
    let value = object;
    for (const k of keys) {
      if (value[k] !== undefined) {
        value = value[k];
      } else {
        return null; // Return null for undefined nested fields
      }
    }
    return value;
  };

  return (
    <>
      <Background />
      <Navbar
        title={"ÆBLER"}
        sortReviews={sortReviews}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
      <div className="reviewItem-wrapper">
        <div className="review-box">
          {sortedReviews.map((review, index) => (
            <ReviewItem key={index} review={review} />
          ))}
        </div>
      </div>
      <InfoBox />
    </>
  );
};

export default Aebler;
