import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ title, sortReviews, sortBy, sortOrder }) => {
  const [sortActive, setSortActive] = useState(false);
  const navMenuRef = useRef(null);

  const handleSortClick = (event) => {
    // Stop the propagation of the click event
    event.stopPropagation();
    setSortActive(!sortActive);
  };

  const chooseSorting = (sortingType) => {
    const newSortOrder = sortingType === sortBy ? !sortOrder : true;
    sortReviews(sortingType, newSortOrder);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
        setSortActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          {title}{" "}
          <i className="material-icons" id="header-icon">
            local_dining
          </i>
        </Link>
        <div className="sort-icon" onClick={handleSortClick}>
          <i
            className={`fa-solid fa-filter ${
              sortActive ? "rotated-counterclockwise" : "rotated-clockwise"
            }`}
          />
        </div>
        <ul
          ref={navMenuRef}
          className={sortActive ? "nav-menu active" : "nav-menu"}
        >
          {[
            "samlet",
            "smag",
            "syrlighed",
            "konsistens",
            "udseende",
            "duft",
            "dato",
          ].map((sortingType) => (
            <li
              className="nav-item"
              key={sortingType}
              onClick={() => chooseSorting(sortingType)}
            >
              <div className="item-content">
                {sortingType.toUpperCase()}
                <div
                  className={`sorting-order-icon ${
                    sortBy === sortingType && sortOrder
                      ? "rotate-up"
                      : "rotate-down"
                  }`}
                >
                  <i
                    className={`fas ${
                      sortBy === sortingType
                        ? sortOrder
                          ? "fa-sort-down"
                          : "fa-sort-down"
                        : ""
                    }`}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
