import React from "react";
import "./InfoBox.css";

const InfoBox = () => {
  const handleCopyEmail = () => {
    // Logic for copying email to clipboard
    const email = "info@ulriksen.vip";
    navigator.clipboard.writeText(email);
    alert(`Email "${email}" copied to clipboard!`);
  };

  return (
    <div className="info-container">
      <i className="material-icons info-icon">info</i>
      <div className="info-box">
        <b>VELKOMMEN</b> til mine anmeldelser.
        <br />
        Sidder du med idéer, kommentarer eller et produkt jeg skal anmelde, så
        smid en besked min vej:
        <br />
        <button id="copyButton" onClick={handleCopyEmail}>
          info@ulriksen.vip
        </button>
      </div>
    </div>
  );
};

export default InfoBox;
