import React from "react";
import Background from "../Background";
import InfoBox from "../InfoBox";
import HeaderTitle from "./HeaderTitle";
import Categories from "./Categories";

import "./Home.css";

const Home = () => {
  return (
    <>
      <Background />
      <HeaderTitle title={"REVIEWS"} />
      <Categories />
      <InfoBox />
    </>
  );
};

export default Home;
