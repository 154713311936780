import React from "react";
import ReactEcharts from "echarts-for-react";

class EChartsGraph extends React.Component {
  render() {
    const { review } = this.props; // Destructure review object from props

    // Check if review object is available and has necessary data
    if (!review || !review.score) {
      return <div>No review data available</div>;
    }

    return (
      <ReactEcharts
        option={this.getOption(review)} // Pass review object to getOption method
        style={{ height: "300px", width: "100%" }}
        className="react_for_echarts"
      />
    );
  }

  getOption = (review) => {
    return {
      color: ["#bf0603"],
      title: {
        text: "Score",
        textStyle: {
          fontFamily: "Montserrat",
          fontSize: "30",
          color: "#FFFFFF",
        },
      },
      legend: {
        textStyle: {
          fontFamily: "Montserrat",
          fontSize: "14",
          color: "#FFFFFF",
        },
      },
      radar: {
        splitNumber: 5,
        shape: "circle",
        indicator: [
          { name: "SMAG", max: 12 },
          { name: "SYRLIGHED", max: 12 },
          { name: "KONSISTENS", max: 12 },
          { name: "UDSEENDE", max: 12 },
          { name: "DUFT", max: 12 },
        ],
        axisName: {
          formatter: "። {value}",
          color: "#FFFFFF",
          fontFamily: "Montserrat",
        },
        splitArea: {
          areaStyle: {
            color: ["#77FFFF", "#77EADF", "#26C3BE", "#64AFE9", "#428BD4"],
            shadowColor: "rgba(0, 0, 0, 0.3)",
            shadowBlur: 15,
          },
        },
        axisLine: {
          lineStyle: {
            color: "rgba(211, 253, 250, 0.8)",
          },
        },
        splitLine: {
          lineStyle: {
            color: "rgba(211, 253, 250, 0.8)",
          },
        },
      },
      series: [
        {
          name: "Review Score",
          type: "radar",
          data: [
            {
              value: [
                review.score.smag,
                review.score.syrlighed,
                review.score.konsistens,
                review.score.udseende,
                review.score.duft,
              ],
              areaStyle: {
                color: "rgba(255, 52, 52, .35)",
              },
            },
          ],
        },
      ],
    };
  };
}

export default EChartsGraph;
