// GridComponent.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Categories.css";

const CategoryGrid = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      fetch("https://api.ulriksen.vip/reviews/categories")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch reviews");
          }
          return response.json();
        })
        .then((data) => {
          const updatedData = data.map((item) => ({
            ...item,
            gridRow: getRandomGridRow(),
          }));
          // Set the reviews with gridRow added
          setData(updatedData);
        })
        .catch((error) => {
          console.error("Error fetching reviews:", error);
          console.log("Error status:", error.status);
          console.log("Error message:", error.message);
        });
    };

    fetchData();
  }, []);

  const getRandomGridRow = () => {
    const rowHeight = Math.floor(Math.random() * 6) + 13;
    return rowHeight;
  };

  const renderGridItems = () => {
    let currentRow = 1;
    let currentColumn = 1;
    let maxRowHeight = Array(3).fill(0); // Track the maximum row height in each column
    let gridItems = [];

    // Sort data based on grid row length
    // data.sort((a, b) => b.gridRow - a.gridRow);

    data.forEach((item, index) => {
      const { title, description, reviewPath, gridRow } = item;

      // Find the minimum height in the current column
      const minRowHeight = Math.min(...maxRowHeight);

      // Find the column with the minimum height
      const minColumn = maxRowHeight.indexOf(minRowHeight) + 1;

      // Set the position of the grid item based on the minimum height column
      currentColumn = minColumn;
      currentRow = minRowHeight + 1;

      // Update the maximum row height for the current column
      maxRowHeight[currentColumn - 1] = currentRow + gridRow - 1;

      gridItems.push(
        <div
          key={index}
          className={`grid-item item-${index + 1}`}
          style={{
            gridColumn: `${currentColumn} / span 1`,
            gridRow: `${currentRow} / span ${gridRow}`,
            backgroundImage: `url(${item.imgUrl})`,
            filter: "brightness(75%)",
          }}
        >
          <div className="info">
            <h1>{title.toUpperCase()}</h1>
            <p>{description}</p>
            <Link to={`/${reviewPath}`}>
              <button>Se reviews</button>
            </Link>
          </div>
        </div>
      );
    });

    return gridItems;
  };

  return (
    <div className="grid-container-wrapper">
      <div className="grid-container">{renderGridItems()}</div>
    </div>
  );
};

export default CategoryGrid;
