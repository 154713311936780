import React from "react";
import Background from "./Background";
import "./NotFound.css";

const NotFound = () => {
  return (
    <>
      <Background />
      <div className="not-found">
        <h1>404</h1>
        <p>Du er da vist kommet på gale veje..</p>
      </div>
    </>
  );
};

export default NotFound;
